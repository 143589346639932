<template>

  <a-modal
    title="修改头像"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="800"
    :footer="null"
    @cancel="cancelHandel">
    <a-row>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :fixedBox="options.fixedBox"
          @realTime="realTime"
        >
        </vue-cropper>
      </a-col>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <div class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img"/>
        </div>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col :lg="2" :md="2">
        <!--                <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false">-->
        <!--                  <a-button icon="upload">选择图片</a-button>-->
        <!--                </a-upload>-->
        <QiniuUpload
          ref="qiniuImageUpload"
          :pickfiles="'pickfiles-files-' + uploadUUID"
          :container="'container-files-' + uploadUUID"
          :notShowDefaultList="true"
          :autoStart="autoStart"
          :filters="filters"
          @onOneUploadComplete="onOneUploadComplete"
          @onUploadComplete="onUploadComplete"
          @onBeforeUpload="onBeforeUpload"
          @onError="onError">
          <div slot="uploader">
            <div :id="'container-files-' + uploadUUID">
              <div v-if="type === 'edit'">
                <a-button
                  type="dashed"
                  :loading="uploadStatus"
                  class="m-r-12 cursor-pointer"
                  icon="cloud-upload"
                  :id="'pickfiles-files-' + uploadUUID"
                >上传头像
                </a-button>
              </div>
            </div>
          </div>
        </QiniuUpload>
      </a-col>
      <a-col :lg="{span: 1, offset: 2}" :md="2">
        <a-button icon="plus" @click="changeScale(1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="minus" @click="changeScale(-1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="undo" @click="rotateLeft"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="redo" @click="rotateRight"/>
      </a-col>
      <a-col :lg="2" :md="2">
      </a-col>
      <a-col :lg="{span: 2, offset: 6}" :md="2">
        <a-button type="primary" @click="finish('blob')">保存</a-button>
      </a-col>
    </a-row>
  </a-modal>

</template>
<script>
  import { VueCropper } from 'vue-cropper'
  // import SERVICE_URLS from '../../api/service.url'
  import QiniuUpload from '@/components/QiniuUpload/QiniuUpload'
  import uploadCommon from '../../components/LocalUpload/mixins/upload-common'

  import dragAndDrop from '../../directives/drag-and-drop'
  import '../../plugins/fileDownload/download.min'
  import { fileIconFormat, humanReadableUnits } from '../../utils/utils'

  import { mapState } from 'vuex'

  export default {
    components: {
      VueCropper,
      QiniuUpload
    },
    mixins: [uploadCommon],
    directives: { dragAndDrop },
    props: {
      type: {
        type: String,
        default: 'edit'
      },
      maxSize: {
        type: String,
        default: '10mb'
      },
      documentDirId: {
        type: Number,
        default: -1
      },
      multiSelection: {
        type: Boolean,
        default: false
      },
      instanceType: {
        type: String,
        default: ''
      },
      autoStart: {
        type: Boolean,
        default: true
      },
      filters: {
        type: Object
      }
    },
    data () {
      return {
        visible: false,
        // id: null,
        confirmLoading: false,
        fileList: [],
        uploading: false,
        options: {
          img: '',
          autoCrop: true,
          autoCropWidth: 200,
          autoCropHeight: 200,
          fixedBox: true
        },
        previews: {},
        fileName: '',
        file: null,
        format: fileIconFormat,
        humanReadableUnits: humanReadableUnits
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      },
      ...mapState({
        bucketUrl: state => {
          return state.user.bucketUrl
        }
      })
    },
    methods: {
      onOneUploadComplete (body, file) {
        this.$nextTick(() => {
          this.file = body
          this.options.img = body.bucketUrl + body.fileUrl
        })
      },
      edit () {
        this.visible = true
        /* 获取原始头像 */
        // this.options.img = this.bucketUrl + this.currentUser.headImg
        this.options.img = ''
      },
      close () {
        this.visible = false
        this.options.img = ''
      },
      cancelHandel () {
        this.close()
      },
      changeScale (num) {
        num = num || 1
        this.$refs.cropper.changeScale(num)
      },
      rotateLeft () {
        this.$refs.cropper.rotateLeft()
      },
      rotateRight () {
        this.$refs.cropper.rotateRight()
      },
      beforeUpload (file) {
        console.log(file)
        let _this = this
        if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
          _this.$message.warning('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
          return false
        }
        this.fileName = file.name
        const reader = new FileReader()
        // 把Array Buffer转化为blob 如果是base64不需要
        // 转化为base64
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.options.img = reader.result
        }
        // 转化为blob
        // reader.readAsArrayBuffer(file)
        return false
      },
      // 上传图片（点击上传按钮）
      finish (type) {
        const _this = this
        // const formData = new FormData()
        // 输出
        if (type === 'blob') {
          this.$refs.cropper.getCropBlob((data) => {
            const img = window.URL.createObjectURL(data)
            this.model = true
            this.modelSrc = img
            console.log(this.file)
            if (_this.file.fileUrl) {
              _this.$emit('ok', _this.file.fileUrl)
              _this.visible = false
            }
            // formData.append('file', this.file, this.fileName)
            // this.$http(this, {
            //   url: SERVICE_URLS.document.upload,
            //   data: formData,
            //   loading: 'ifSub',
            //   noTips: 'true',
            //   success: (data) => {
            //     console.log(data)
            //     _this.fileName = ''
            //     _this.$emit('ok', data.body.path)
            //     _this.visible = false
            //   }
            // })
          })
        } else {
          // this.$refs.cropper.getCropData((data) => {
          //   this.model = true
          //   this.modelSrc = data
          //   // console.log(data)
          // })
          if (this.file.fileUrl) {
            _this.$emit('ok', this.file.fileUrl)
            _this.visible = false
          }
        }
      },
      okHandel () {
        const vm = this
        vm.confirmLoading = true
        setTimeout(() => {
          vm.confirmLoading = false
          vm.close()
          vm.$message.success('上传头像成功')
        }, 2000)
      },
      realTime (data) {
        this.previews = data
      }
    }
  }
</script>

<style lang="less" scoped>

  .avatar-upload-preview {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
